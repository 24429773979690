<template>
  <section class="bg-light h-100 p-3">
    <div class="d-flex justify-content-between">
      <h2>Liste des articles</h2>
      <button class="btn btn-primary" @click="navigateNewArticle()">
        Ajouter
      </button>
    </div>
    <hr />
    <div>
      <table class="table table-hover table-responsive">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Titre</th>
            <th scope="col">Catégorie</th>
            <th scope="col">Date de publication</th>
            <th scope="col">Auteur</th>
            <th scope="col" class="text-center">Nombre de Commentaires</th>
            <th scope="col" class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in pageOfItems" :key="item.id_article">
            <th scope="row ">
              {{ item.id_article }}
            </th>
            <td class="d-flex">
              {{ item.title_article }}
              <div class="ms-2">
                <Star class="text-primary" v-if="item.isSelected_article" />
                <StarOut v-else class="text-primary" />
              </div>
            </td>
            <td>{{ item.name_categorie_article }}</td>
            <td>
              {{
                moment(item.date_article).calendar(null, {
                  sameWeek: "ddd",
                  sameElse: "DD MMMM YYYY",
                  sameDay: "[Aujourd'hui]",
                  nextDay: "[Demain]",
                  lastWeek: "dddd [dernier]",
                  nextWeek: "dddd",
                  lastDay: "[Hier]",
                })
              }}
            </td>
            <td>{{ item.fname_collab }}</td>
            <td class="text-center">{{ item.NbCommentaire }}</td>
            <td class="text-center d-flex gap-3 justify-content-center">
              <div>
                <IcoEye
                  class="cursor-pointer"
                  @click="navigateView(item.id_article)"
                />
              </div>
              <div>
                <IcoComment
                  class="cursor-pointer"
                  @click="navigateListComment(item.id_article)"
                />
              </div>
              <div>
                <IcoPen
                  v-if="checkIfMine(item.id_collab)"
                  class="cursor-pointer"
                  @click="navigateToArticle(item.id_article)"
                />
              </div>
              <div>
                <IcoDelete
                  v-if="checkIfMine(item.id_collab)"
                  class="cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDelete(item.id_article)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-center">
        <CustomPagination
          :pageSize="5"
          :items.sync="initialData"
          @changePage="onChangePage"
        ></CustomPagination>
      </div>
    </div>
    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Êtes-vous sûr ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">
                L'article sera supprimé avec tous ces commentaires
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              @click="deleteArticle()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->
  </section>
</template>

<script>
import IcoEye from "vue-material-design-icons/Eye.vue";
import IcoDelete from "vue-material-design-icons/Delete.vue";
import { getAllArticle, deleteArticleById } from "../../api/article";
import CustomPagination from "../../components/public/Pagination.vue";
import IcoPen from "vue-material-design-icons/Pen.vue";
import IcoComment from "vue-material-design-icons/CommentMultiple.vue";
import { success } from "../../utils/toast";
import { checkIfMine } from "../../utils/index";
import Star from "vue-material-design-icons/Star.vue";
import StarOut from "vue-material-design-icons/StarOutline.vue";

export default {
  name: "ArticleListe",
  components: {
    Star,
    StarOut,
    IcoEye,
    IcoDelete,
    CustomPagination,
    IcoPen,
    IcoComment,
  },

  data() {
    return {
      idDelete: 0,
      loading: true,
      pageOfItems: [],
      initialData: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      getAllArticle().then((results) => {
        this.initialData = results.data;
        this.loading = false;
      });
    },
    navigateView(id) {
      this.$router.push(`/select-article/${id}`);
    },
    navigateNewArticle() {
      this.$router.push(`/admin/nouvel-article`);
    },
    navigateToArticle(id) {
      this.$router.push(`/admin/article/${id}`);
    },
    navigateListComment(id) {
      this.$router.push(`/admin/article/commentaire/${id}`);
    },
    initDelete(id) {
      this.idDelete = id;
    },
    deleteArticle() {
      this.loading = true;
      deleteArticleById(this.idDelete).then(() => {
        this.fetch();
        this.loading = false;
        this.$refs.CloseDelete.click();
        success("Article supprimé");
      });
    },
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
    checkIfMine(id) {
      return checkIfMine(id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
